import React from "react";
import BeneTxnViewall from "../../containers/BeneficiaryTransactions/BeneTxnViewall/index";
import "./index.css";

const TransactionHistory = ({
  bene_account_id,
  bene_account_name,
  onClose,
}) => {
  return (
    <>
      <div
        className="transactionHistory_pop_div fixed overflow-hidden rounded-[20px] z-[99999] bg-[#1D3A6D]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-full w-full">
          <BeneTxnViewall
            bene_account_name={bene_account_name}
            bene_account_id={bene_account_id}
            onClose={onClose}
            typepop={"transactionHistorypopup"}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;

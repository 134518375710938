import React, { useCallback, useEffect, useRef, useState } from "react";
import "./index.css";
import { Box, TextField, Tooltip, tooltipClasses } from "@mui/material";
import SearchBar from "../../../components/_utils/search/SearchBar";
import {
  beneTxnViewAllAcc,
  setToggle,
  setIsApprovalStatusSelected,
} from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import formatTotalBalance from "../../../utils/formatTotalBalance";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount.js";
import DataCountPopup from "../../../components/_utils/DataCountPopup/index";
import PaginationComponent from "../../../components/_utils/PaginationComponent/index";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import { BeneTxnRequireApprovalPage } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import closeBtnImg from "../../../assets/CategoryFilterIcons/cross.png";
import rentalImg from "../../../assets/CategoryFilterIcons/rental.png";
import InfiniteScroll from "react-infinite-scroll-component";
import BeneTxnViewAllCard from "../../../components/BeneTransaction/BeneTxnViewAllCard";
import noaccount from "../../../assets/Beneficiary Icons/noaccfound.png";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import { useLocation, useNavigate } from "react-router-dom";
import BeneTxnStatusPopover from "../../../components/BeneTransaction/BeneTxnStatusPopover";
import BeneTxnFilterSidebar from "../../../components/BeneTransaction/BeneTxnFilterSidebar";
import arrowup from "../../../assets/OwnerAccount/arrowup.png";
import arrowforward from "../../../assets/Beneficiary Icons/arrowForward.png";
import arrowdown from "../../../assets/OwnerAccount/arrowdown1.png";
import ArrowDown8 from "../../../assets/OwnerAccount/arrowdown8.png";
import FilterBlue from "../../../assets/OwnerAccount/filterblue.png";
import Filter from "../../../assets/ViewAllTransactions/filter.png";
import ArrowUp4 from "../../../assets/OwnerAccount/arrowdown4png.png";
import ArrowLeft from "../../../assets/OwnerAccount/arrowleft.png";
import ArrowRight from "../../../assets/OwnerAccount/arrowright.png";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import GridBlue from "../../../assets/OwnerAccount/gridview-blue.png";
import GridWhite from "../../../assets/OwnerAccount/gridview-white.png";
import ListBlue from "../../../assets/OwnerAccount/listview-blue.png";
import ListWhite from "../../../assets/OwnerAccount/listview-white.png";
import closeIcon from "../../../../src/assets/General Icons/close.svg";
import DistributionStatusPopover from "../../../components/BeneTransaction/DistributionStatusPopover";
import TxnSearchBar from "../../../components/_utils/TxnSearchBar";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

import BeneTxnAccountModal from "../../../components/BeneTransaction/BeneTxnAccount/BeneTxnAccountModal/index.jsx";
import OwnerAccountPopUp from "../../../components/BeneTransaction/OwnerAccountPopUp/index.jsx";

import BeneTxnViewModalOwner from "../../../components/BeneTransaction/BeneTxnViewModalOwner";
import search_blue from "../../../assets/ViewAllTransactions/search_blue.png";
import search_white from "../../../assets/Approve transactions/search-white.png";
import { debounce } from "lodash";
import BeneTxnListView from "../../../components/BeneTransaction/BeneTxnListView";
import ArrowUp from "../../../assets/OwnerAccount/arrowup.png";

import PrevIcon from "../../../assets/Beneficiary Icons/PreviousIcon.png";
import NextIcon from "../../../assets/Beneficiary Icons/NextIcon.png";

const BeneTxnViewall = (props) => {
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [hideCarousal, setHideCaroursal] = useState(false);
  const { BeneTxnDashBoardData, BeneTxnDashBoardLoading } = useSelector(
    (state) => state?.beneTxnViewAccData
  );
  const dropdownOptions =
    window.innerWidth < 1600
      ? [50, 100]
      : window.innerWidth < 2560
      ? [50, 100]
      : [50, 100];

  const location = useLocation();
  const navigate = useNavigate();
  // const approvalStatus = location.state ? location.state.approvalStatus : "";
  const { approvalStatus, DistributionDashBoard } = location.state || {};
  const [DashBoardStatus, SetDashBoardStatus] = useState(approvalStatus);
  const category = location.state ? location.state.category : "";
  const [initCategory, setInitCategory] = useState(category ? category : "");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("all");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(
    category ? category : ""
  );
  const [searchUserText, setSearchUserText] = useState("");
  const [showDataCountPopup, setShowDataCountPopup] = useState(false);
  const [createdOnFilter, setCreatedOnFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isPaymentMethod, setIsPaymentMethod] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [txnAmount, setTxnAmount] = useState("");
  const [txnAmountFilter, setTxnAmountFilter] = useState("");
  const [mainData, setMainData] = useState([]);
  // const [searchTxnAmount, setSearchTxnAmount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedBeneAccId, setSelectedBeneAccId] = useState(
    props?.bene_account_id ? props?.bene_account_id : ""
  );
  const [selectedOwnerAccId, setSelectedOwnerAccId] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [beneAccountId, setBeneAccountId] = useState(
    props?.bene_account_id ? props?.bene_account_id : null
  );
  const [accountName, setAccountName] = useState(
    props?.bene_account_name ? props?.bene_account_name : ""
  );
  const [accountNameOwner, setAccountNameOwner] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownPaymentOpen, setDropdownPaymentOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  // const [dropdownOptions, setDropdownOptions] = useState([50, 100]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [currentStatusDistribution, setCurrentStatusDistribution] =
    useState("all");
  const [isDistributionSelected, setIsDistributionSelected] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isViewModalOpenOwner, setIsViewModalOpenOwner] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDistributionPopoverOpen, setIsDistributionPopoverOpen] =
    useState(false);
  const [inputValue, setInputValue] = useState(1);
  const [accountNumber, setAccountNumber] = useState("");
  const [totalBalanceAmount, setTotalBalanceAmount] = useState(0);
  const [selectAmtType, setSelectAmtType] = useState("Default");

  const [IFSC, setIFSC] = useState("");
  const [bankLogo, setBankLogo] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [txnPayLoad, setTxnPayLoad] = useState({
    bankAccountName: "",
    beneAccountId: "",
    bankAccountIFSC: "",
    bankAccountNo: "",
    categories: "",
    pan: "",
    ownerAccountId: "",
    amount: "",
    ownerbankAccountNo: "",
  });
  const dispatch = useDispatch();
  const {
    beneTxnViewAllAccData,
    toggle,
    storedPage,
    setIsApprovalStatusSelected,
  } = useSelector((state) => state.beneTxnViewAccData);

  useEffect(() => {
    const newTotalPages = Math.ceil(totalCount / pageSize);
    setTotalPages(newTotalPages);

    setCurrentPage((prevPage) => {
      if (newTotalPages === 0) {
        return 1;
      }
      if (prevPage > newTotalPages) {
        return newTotalPages;
      }
      return prevPage;
    });
  }, [totalCount, pageSize]);

  const setCurrentWidth = () => {
    setSelectedValue(dropdownOptions[0]);
    setPageSize(dropdownOptions[0]);
  };
  window.onresize = setCurrentWidth;

  // useEffect(() => {
  //   setDropdownOptions([50, 100].filter((value) => value !== selectedValue));
  // }, [selectedValue]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (scrollRef) {
      if (scrollRef?.current?.scrollWidth > scrollRef?.current?.clientWidth) {
        setHideCaroursal(true);
      } else {
        setHideCaroursal(false);
      }
    }
  }, [
    currentCategory,
    startDateFilter,
    endDateFilter,
    selectedBeneAccId,
    isPaymentMethod,
    accountNumber,
    txnAmountFilter,
  ]);

  const beneTxnNext = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 1024,
        behavior: "smooth",
      });
    }
    setShowSecondContent(!showSecondContent);
    setDisableNext(true);
    setDisablePrev(false);
  };

  const beneTxnPrev = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -1024,
        behavior: "smooth",
      });
    }
    setShowSecondContent(!showSecondContent);
    setDisablePrev(true);
    setDisableNext(false);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(Number(storedPage));
    // setAccumulatedData([]);
    toggleDropdown();
  };

  const handleSelectAmt = () => {
    if (selectAmtType == "Default") {
      return "66px";
    } else if (selectAmtType == "Lakhs") {
      return "128px";
    } else if (selectAmtType == "Crores") {
      return "183px";
    } else if (selectAmtType == "Millions") {
      return "244px";
    } else if (selectAmtType == "Billions") {
      return "305px";
    }
  };

  const lastElementRef = useRef();
  const filterRef = useRef(null);
  const viewModalRef = useRef(null);
  const settlementDropdownRef = useRef(null);
  const categoryDropdownRef = useRef(null);
  const paymentDropdownRef = useRef(null);

  const dateRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchBarRef = useRef(null);

  const scrollRef = useRef(null);

  const handleFilterCategory = (selectedCategoryName) => {
    setCurrentCategory(selectedCategoryName);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
    setShowDatePicker(false);
  };
  const handleOpenViewModal = () => {
    setIsViewModalOpen(true);
  };
  const handleOpenViewModalOwner = () => {
    setIsViewModalOpenOwner(true);
  };

  const handleCloseViewModalOwner = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsViewModalOpenOwner(false);
  };
  const handleCloseViewModal = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setIsViewModalOpen(false);
  };

  const handleAccountSelection = (
    accountName,
    accountNumber,
    ifsc,
    beneAccountId
  ) => {
    setAccountName(accountName);
    setAccountNumber(accountNumber);
    setIFSC(ifsc);
    setBeneAccountId(beneAccountId);

    setTxnPayLoad({
      ...txnPayLoad,
      bankAccountName: accountName,
      bankAccountNo: accountNumber,
      bankAccountIFSC: ifsc,
      beneAccountId: beneAccountId,
    });
    handleCloseViewModal();
  };

  const handleAmountSelected = (amount) => {
    setTxnPayLoad({
      ...txnPayLoad,
      amount: txnAmountFilter,
    });
  };

  const handleAccountSelectionOwner = (
    accountName,
    accountNumber,
    ifsc,
    beneAccountId
  ) => {
    setTxnPayLoad({
      ...txnPayLoad,
      ownerAccountId: beneAccountId,
      ownerbankAccountNo: accountNumber,
    });
    handleCloseViewModalOwner();
  };

  useEffect(() => {
    const handleAction = async () => {
      if (currentStatus === "require approval") {
        try {
          setLoading(true);
          dispatch(checkUsertoken());
          const responseApproval = await dispatch(
            BeneTxnRequireApprovalPage({
              page_no: currentPage,
              page_item_count: pageSize,
              category: currentCategory,
            })
          );

          const newApprovalData = responseApproval?.payload?.transactions || [];
          setMainData(responseApproval?.payload);
          setAccumulatedData(newApprovalData);
          setTotalCount(responseApproval?.payload?.total_count || 0);
          setLoading(false);
          setShowDataCountPopup(true);
        } catch (error) {
          setAccumulatedData([]);
          setMainData([]);
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          let queryParams = {
            page_no: currentPage,
            page_item_count: pageSize,
          };

          const addParamsBasedOnSelection = () => {
            if (
              currentStatusDistribution &&
              currentStatusDistribution !== "all"
            ) {
              queryParams.distribution_lifecycle_status =
                currentStatusDistribution;
            }

            if (
              DistributionDashBoard === false &&
              DashBoardStatus &&
              DashBoardStatus !== "all"
            ) {
              queryParams.lifecycle_status = DashBoardStatus;
            }
            if (
              DashBoardStatus &&
              DashBoardStatus !== "all" &&
              DistributionDashBoard === true
            ) {
              queryParams.distribution_lifecycle_status = DashBoardStatus;
            }
            if (currentStatus && currentStatus !== "all") {
              queryParams.lifecycle_status = currentStatus;
            }

            if (currentCategory && currentCategory !== "Select category") {
              queryParams.category = currentCategory;
            }
            if (
              startDateFilter &&
              endDateFilter &&
              !isNaN(Number(startDateFilter)) &&
              !isNaN(Number(endDateFilter))
            ) {
              queryParams.start_date = startDateFilter + 86400;
              queryParams.end_date = endDateFilter + 86400;
            }
            if (isPaymentMethod && isPaymentMethod !== "All") {
              queryParams.payment_method = isPaymentMethod;
            }

            if (txnAmount !== "") {
              queryParams.amount = txnAmount;
            }
            if (txnAmountFilter !== "") {
              queryParams.amount = txnAmountFilter;
            }
            if (selectedBeneAccId !== "") {
              queryParams.beneficiary_account_id = selectedBeneAccId;
            }
            if (selectedOwnerAccId !== "") {
              queryParams.owner_account_id = selectedOwnerAccId;
            }
          };

          addParamsBasedOnSelection();

          dispatch(checkUsertoken());
          const response = await dispatch(beneTxnViewAllAcc(queryParams));

          const newData = response?.payload?.transactions || [];
          setMainData(response?.payload);
          setAccumulatedData(newData);
          setTotalBalanceAmount(Number(response?.payload?.total_amount || 0));
          setTotalCount(response?.payload?.total_count || 0);
          setLoading(false);
          setShowDataCountPopup(true);
        } catch (error) {
          setAccumulatedData([]);
          setMainData([]);

          setLoading(false);
        }
      }
    };
    handleAction();
  }, [
    dispatch,
    currentPage,
    currentStatus,
    currentStatusDistribution,
    currentCategory,
    startDateFilter,
    endDateFilter,
    pageSize,
    isPaymentMethod,
    txnAmount,
    txnAmountFilter,
    selectedBeneAccId,
    selectedOwnerAccId,
  ]);

  useEffect(() => {
    setInputValue(String(currentPage).padStart(2, "0"));
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(1);
    setInputValue("1");
  }, []);
  const getAccStatus = (item, ele) => {
    if (ele === "distribution") {
      setCurrentStatusDistribution(item);
      setIsDistributionSelected(true);
      setIsStatusSelected(false);
    } else if (ele === "status") {
      setCurrentStatus(item);
      setIsDistributionSelected(false);
      setIsStatusSelected(true);
    }
  };

  const handleFilterSticker = () => {
    setCurrentCategory("");
    setInitCategory("");
    if (props?.typepop !== "transactionHistorypopup") {
      navigate(`/transaction/beneficiary_transaction/view_all`, {
        state: { approvalStatus: "all", category: null },
      });
    }
  };
  useEffect(() => {
    updateIsFilterApplied();
  }, [
    currentCategory,
    startDateFilter,
    endDateFilter,
    txnAmountFilter,
    isPaymentMethod,
    selectedBeneAccId,
    selectedOwnerAccId,
  ]);

  const updateIsFilterApplied = () => {
    setIsFilterApplied(
      !!currentCategory ||
        !!(startDateFilter && endDateFilter) ||
        !!isPaymentMethod ||
        !!selectedBeneAccId ||
        txnAmountFilter ||
        selectedOwnerAccId
    );
  };
  const getArrowIcon = (isSidebarOpen, isFilterApplied) => {
    if (isSidebarOpen) {
      return isFilterApplied ? ArrowUp4 : arrowup;
    } else {
      return isFilterApplied ? ArrowDown8 : arrowdown;
    }
  };
  const handleFilterDateRange = (startDate, endDate) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };
  const handlePaymentMethod = (selectedSettlement) => {
    setIsPaymentMethod(selectedSettlement);
  };

  const handleFilterAmountMethod = () => {
    setTxnAmountFilter("");
    setTxnAmount("");
    setTxnPayLoad({
      ...txnPayLoad,
      amount: "",
    });
  };

  const handleFilterDateSticker = () => {
    setStartDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    setEndDate("");
  };

  const handleFilterBeneAccSticker = () => {
    if (props?.typepop !== "transactionHistorypopup") {
      setBeneAccountId(null);
      setSelectedBeneAccId("");
      setAccountName("");
    }
  };
  const handleFilterPaymentSticker = () => {
    setIsPaymentMethod("");
    setPaymentMethod("");
  };

  const handleFilterPaymentStickerOwner = () => {
    setAccountNumber("");
    setSelectedOwnerAccId("");
    setTxnPayLoad({
      ...txnPayLoad,
      ownerbankAccountNo: "",
      ownerAccountId: "",
    });
  };
  const handleSearch = (text) => {
    if (text) {
      const sanitizedText = text.replace(/,/g, "");
      const amount = parseFloat(sanitizedText);
      if (!isNaN(amount) && amount >= 0) {
        setTxnAmount(sanitizedText);
      } else if (sanitizedText === "") {
        setTxnAmount("");
      }
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();

    setTxnAmount(e.target.value);
  };
  const handleTxnAmountSearch = (selectedTxnId) => {
    setTxnAmount(selectedTxnId);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isViewModalOpen || isViewModalOpenOwner) {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
          return;
        }
        return;
      }

      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !(
          settlementDropdownRef.current &&
          settlementDropdownRef.current.contains(event.target)
        ) &&
        !(
          categoryDropdownRef.current &&
          categoryDropdownRef.current.contains(event.target)
        ) &&
        !(dateRef.current && dateRef.current.contains(event.target)) &&
        !(
          paymentDropdownRef.current &&
          paymentDropdownRef.current.contains(event.target)
        )
      ) {
        setIsSidebarOpen(false);
        setIsDropdownOpen(false);
        setDropdownOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowSearchBar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isViewModalOpen, isViewModalOpenOwner]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };
  const marginTop = isFilterApplied
    ? "14.8rem"
    : isPopoverOpen || isDistributionPopoverOpen
    ? "10rem"
    : showSearchBar
    ? "18rem"
    : // : toggle
      // ? "4rem"
      "10rem";

  const getIcon = () => {
    if (showSearchBar) {
      return txnAmount !== "" ? ArrowUp4 : arrowup;
    } else {
      return txnAmount !== "" ? ArrowDown8 : arrowdown;
    }
  };
  const handlePageChange = useCallback(
    debounce((page) => {
      const pageNumber = Number(page);
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      } else if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(1);
      }
    }, 300),

    [totalPages]
  );

  const debouncedHandlePageChange = debounce((value) => {
    handlePageChange(value);
  }, 1000);

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (value === "") {
        setInputValue("");
        setCurrentPage(1);
      } else if (numericValue > totalPages) {
        setInputValue(
          String(totalPages).padStart(String(totalPages).length, "0")
        );
        setCurrentPage(totalPages);
      } else if (numericValue < 1) {
        setInputValue(1);
        setCurrentPage(1);
      } else {
        setInputValue(value);
        debouncedHandlePageChange(value);
      }
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div
        id="beneScrollContainer"
        className={`bg-custom-appBack flex flex-col h-screen`}
      >
        {isPopoverOpen && (
          <div className="acc_blackTxn_div bg-custom-backdrop"></div>
        )}
        {isSidebarOpen && (
          <div className="acc_blackTxn_div bg-custom-backdrop"></div>
        )}
        {isDistributionPopoverOpen && (
          <div className="acc_blackTxn_div bg-custom-backdrop"></div>
        )}

        {
          <div
            style={{
              zIndex: "9999",
              width:
                props?.typepop === "transactionHistorypopup" ? "96%" : "100%",
              bottom: props?.typepop === "transactionHistorypopup" ? "4%" : "0",
              borderRadius:
                props?.typepop === "transactionHistorypopup"
                  ? "0 0 20px 20px"
                  : "0",
              // backgroundColor:
              //   isDistributionPopoverOpen || isSidebarOpen || isPopoverOpen
              //     ? "#252525"
              //     : "#244277",
              opacity:
                isDistributionPopoverOpen || isSidebarOpen || isPopoverOpen
                  ? 0.1
                  : 1,
            }}
            className="fixed w-full bg-[#244277] h-[50px]"
          >
            <PaginationComponent
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              data={mainData}
              pageSize={pageSize}
              dropdownOptions={dropdownOptions}
              handlePagination={handlePagination}
              currentPage={currentPage}
            />
          </div>
        }

        {/* 
         This is data Count Popover
        
        {showDataCountPopup && accumulatedData?.length > 0 && (
          <div className="fixed bottom-8 w-full flex justify-center items-center z-[99999]">
            <DataCountPopup data={mainData} pageSize={currentPage} />
          </div>
        )} */}
        {isViewModalOpen && (
          <div
            style={{ zIndex: "2147483647" }}
            className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
          >
            <BeneTxnAccountModal
              setIsSidebarOpen={setIsSidebarOpen}
              onClose={handleCloseViewModal}
              onClosepop={setIsViewModalOpen}
              onAccountSelect={handleAccountSelection}
              setTxnPayLoad={setTxnPayLoad}
              txnPayLoad={txnPayLoad}
              accountNumber={accountNumber}
              IFSC={IFSC}
              bankLogo={bankLogo}
            />
          </div>
        )}

        {isViewModalOpenOwner && (
          <div
            style={{ zIndex: "2147483647" }}
            className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
          >
            <OwnerAccountPopUp
              setIsSidebarOpen={setIsSidebarOpen}
              onClosed={handleCloseViewModalOwner}
              onAccountSelect={handleAccountSelectionOwner}
              setTxnPayLoad={setTxnPayLoad}
              setOpenOwner={setIsViewModalOpenOwner}
              txnPayLoad={txnPayLoad}
              accountNumber={accountNumber}
              IFSC={IFSC}
              bankLogo={bankLogo}
              flow={"txnViewAll"}
            />
          </div>
        )}

        <div
          className="bg-custom-appBack flex flex-col h-screen"
          id=" benetxnmain "
          style={{
            overflow: isSidebarOpen && "hidden",
            // opacity: isViewModalOpen || isViewModalOpenOwner ? 0.2 : 1,
          }}
        >
          <div
            style={{
              display:
                props?.typepop === "transactionHistorypopup" ? "flex" : "none",
            }}
            className={`transactionHistoryPop_topDiv ${
              props?.typepop === "transactionHistorypopup"
                ? "flex flex-row justify-between items-center p-5"
                : "hidden"
            }`}
          >
            <div className="flex flex-col space-y-1">
              <p className="text-base text-[#F9F9F9] font-sans font-semibold">
                Transaction History
              </p>
              <p className="text-xs text-[#F9F9F9] font-sans font-normal">
                All transactions related to the beneificary are listed below
              </p>
            </div>
            <div
              className="flex justify-center items-center cursor-pointer w-7 h-7 rounded-[5px] bg-[#314D7E] hover:bg-[#506994]"
              onClick={() => props?.onClose()}
            >
              <img src={closeIcon} className="w-4 h-4" />
            </div>
          </div>
          <div className="beneTxnViewAllNavbar">
            <div className="beneTxnViewAllContent"></div>
            <div
              className="beneTxnViewAllSec1"
              style={{
                height: showSearchBar ? "292px" : "175px",
                transition: showSearchBar
                  ? "all 0.2s ease-in"
                  : "all 0.2s ease-in",
                width:
                  props?.typepop === "transactionHistorypopup" ? "96%" : "100%",
                borderRadius:
                  props?.typepop === "transactionHistorypopup"
                    ? "20px 20px 0px 0px"
                    : "0px",
              }}
            >
              <div
                className="beneTxnViewAllSec2"
                style={{
                  width:
                    props?.typepop === "transactionHistorypopup"
                      ? "100%"
                      : "1024px",
                  background:
                    props?.typepop === "transactionHistorypopup"
                      ? null
                      : "linear-gradient(to right, #506994 32.5%, #3a5686 20%)",
                }}
              >
                <div
                  className="beneTxnViewAll_middleBar"
                  style={{
                    borderBottom: showSearchBar ? "1px solid #1D3A6D" : "",
                    // height: showSearchBar ? "124px" : "102px",
                  }}
                >
                  <div
                    className="beneTxnViewAllWholeSection"
                    // style={{
                    //   borderBottom: showSearchBar ? "1px solid #1D3A6D" : "",
                    // }}
                  >
                    <div className="beneTxnViewAllParentdiv">
                      <div className="beneTxnViewAllchild1">
                        <BeneTxnStatusPopover
                          typepop={props?.typepop}
                          data={accumulatedData}
                          getAccStatus={getAccStatus}
                          DashBoardStatusData={DashBoardStatus}
                          DistributionStatusData={DistributionDashBoard}
                          SetDashBoardStatusData={SetDashBoardStatus}
                          selectedStatus={currentStatus}
                          setPage={setCurrentPage}
                          setIsApprovalStatusSelected={
                            setIsApprovalStatusSelected
                          }
                          isDistributionSelected={isDistributionSelected}
                          setIsDistributionSelected={setIsDistributionSelected}
                          isStatusSelected={isStatusSelected}
                          setIsStatusSelected={setIsStatusSelected}
                          setIsPopoverOpen={setIsPopoverOpen}
                          isPopoverOpen={isPopoverOpen}
                          currentCategory={currentCategory}
                          setCurrentCategory={setCurrentCategory}
                          setPaymentMethod={setPaymentMethod}
                          setTempEndDate={setTempEndDate}
                          setTempStartDate={setTempStartDate}
                          setSelectedOwnerAccId={setSelectedOwnerAccId}
                          setTxnAmountFilter={setTxnAmountFilter}
                          setSelectedBeneAccId={setSelectedBeneAccId}
                          sendPaymentMethod={handlePaymentMethod}
                          setStartDateFilter={setStartDateFilter}
                          setEndDateFilter={setEndDateFilter}
                          setInitCategory={setInitCategory}
                          setTxnPayLoad={setTxnPayLoad}
                          txnPayLoad={txnPayLoad}
                          setBeneAccountId={setBeneAccountId}
                          setEndDate={setEndDate}
                          setStartDate={setStartDate}
                          setAccountName={setAccountName}
                        />
                      </div>
                    </div>

                    <div className="ver__divider"></div>

                    <div
                      className="beneTxnViewallAmountSection"
                      style={{ height: "59px", marginTop: "0" }}
                    >
                      <div className="beneTxnViewallTotalAmount">
                        <DistributionStatusPopover
                          typepop={props?.typepop}
                          setAccountName={setAccountName}
                          setBeneAccountId={setBeneAccountId}
                          setSelectedBeneAccId={setSelectedBeneAccId}
                          data={accumulatedData}
                          getAccStatus={getAccStatus}
                          selectedStatus={currentStatusDistribution}
                          setPage={setCurrentPage}
                          isDistributionSelected={isDistributionSelected}
                          setIsDistributionSelected={setIsDistributionSelected}
                          isStatusSelected={isStatusSelected}
                          setIsStatusSelected={setIsStatusSelected}
                          setIsDistributionPopoverOpen={
                            setIsDistributionPopoverOpen
                          }
                          isDistributionPopoverOpen={isDistributionPopoverOpen}
                          DashBoardStatusData={DashBoardStatus}
                          DistributionStatusData={DistributionDashBoard}
                          SetDashBoardStatusData={SetDashBoardStatus}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="totalBalance">
                    <div className="totalBalanceAmountDiv">
                      {/* <span
                        style={{
                          display: "inline-block",
                          fontSize: "14px",
                          paddingRight: "5px",
                        }}
                      >
                        ₹
                      </span> */}
                      {`₹ ` +
                        Number(totalBalanceAmount)?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </div>
                    <p
                      style={{
                        width: "250px",
                        height: "17px",
                        font: "normal normal normal 12px/17px Open Sans",
                        color: "#C9C9C9",
                      }}
                    >
                      Total balance:{" "}
                      {formatTotalBalance(totalBalanceAmount).prefix == ""
                        ? ""
                        : "in " + formatTotalBalance(totalBalanceAmount).prefix}
                    </p>
                  </div>
                  <div className="secondDivElement">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Search"
                    >
                      <div
                        ref={searchBarRef}
                        className={`beneTxn_viewAll_search_div ${
                          txnAmount !== "" ? "searched" : ""
                        } hover:bg-[#506994]`}
                        onClick={() => {
                          toast.info("Coming Soon...", toast_position);
                          // setShowSearchBar((prev) => !prev);
                          // setIsSidebarOpen(false);
                        }}
                      >
                        <img
                          className="bene_txn_search-image"
                          src={txnAmount ? search_blue : search_white}
                          alt=""
                        />
                        <img
                          src={getIcon()}
                          alt=""
                          className="w-[12px] h-[12px]"
                        />

                        {showSearchBar && (
                          <>
                            <TxnSearchBar
                              handleSearch={handleSearch}
                              showSearchBar={showSearchBar}
                              setShowSearchBar={setShowSearchBar}
                              setCurrentPage={setCurrentPage}
                              // setSearchTxnAmount={setSearchTxnAmount}
                              txnAmount={txnAmount}
                              setTxnAmount={setTxnAmount}
                              handlePaste={handlePaste}
                              toggleShowSearchBar={() => {
                                setShowSearchBar(!showSearchBar);
                              }}
                              bgColor={"bg-custom-card"}
                              bgHover={"hover:bg-custom-cardHover"}
                            />
                          </>
                        )}
                      </div>
                    </Tooltip>
                    <div
                      ref={filterRef}
                      className={`beneTxn_viewall_filter_div ${
                        currentCategory !== "" ||
                        startDateFilter ||
                        endDateFilter ||
                        isPaymentMethod ||
                        selectedOwnerAccId ||
                        txnAmountFilter ||
                        selectedBeneAccId
                          ? "filtered"
                          : ""
                      }`}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Filters"
                      >
                        <div
                          style={{
                            pointerEvents:
                              isViewModalOpen || isViewModalOpenOwner
                                ? "none"
                                : "",
                          }}
                          className="beneTxnViewAll_filter_container "
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSidebar();
                            setShowSearchBar(false);
                          }}
                        >
                          <Box className="beneTxnViewAll_filter_box">
                            <img
                              src={isFilterApplied ? FilterBlue : Filter}
                              alt=""
                              className="bene-txn-filter-image"
                            />
                          </Box>
                          <Box className="beneTxnViewAll_filter_box">
                            {" "}
                            <img
                              src={getArrowIcon(isSidebarOpen, isFilterApplied)}
                              alt=""
                              className="filter-images-ele"
                            />
                          </Box>
                        </div>
                      </Tooltip>
                      <BeneTxnFilterSidebar
                        typepop={props?.typepop}
                        isOpen={isSidebarOpen}
                        onClose={toggleSidebar}
                        sendCategory={handleFilterCategory}
                        handleFilterDateRange={handleFilterDateRange}
                        setPage={setCurrentPage}
                        setIsSidebarOpen={setIsSidebarOpen}
                        initCategory={initCategory}
                        setInitCategory={setInitCategory}
                        isPaymentMethod={isPaymentMethod}
                        setEndDateFilter={setEndDateFilter}
                        setStartDateFilter={setStartDateFilter}
                        sendPaymentMethod={handlePaymentMethod}
                        setTxnPayLoad={setTxnPayLoad}
                        handleAmountSelected={handleAmountSelected}
                        txnPayLoad={txnPayLoad}
                        isSidebarOpen={isSidebarOpen}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        selectedBeneAccId={selectedBeneAccId}
                        setSelectedBeneAccId={setSelectedBeneAccId}
                        beneAccountId={beneAccountId}
                        setBeneAccountId={setBeneAccountId}
                        accountName={accountName}
                        setAccountName={setAccountName}
                        showDatePicker={showDatePicker}
                        setShowDatePicker={setShowDatePicker}
                        isFilterApplied={isFilterApplied}
                        setIsFilterApplied={setIsFilterApplied}
                        settlementDropdownRef={settlementDropdownRef}
                        paymentDropdownRef={paymentDropdownRef}
                        categoryDropdownRef={categoryDropdownRef}
                        dateRef={dateRef}
                        dropdownOpen={dropdownOpen}
                        setIsDropdownOpen={setIsDropdownOpen}
                        isDropdownOpen={isDropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                        isDropdownPaymentOpen={isDropdownPaymentOpen}
                        setDropdownPaymentOpen={setDropdownPaymentOpen}
                        setTxnAmountFilter={setTxnAmountFilter}
                        txnAmountFilter={txnAmountFilter}
                        onClosed={handleCloseViewModal}
                        onAccountSelect={handleAccountSelection}
                        isViewModalOpen={isViewModalOpen}
                        handleOpenViewModal={handleOpenViewModal}
                        viewModalRef={viewModalRef}
                        handleOpenViewModalOwner={handleOpenViewModalOwner}
                        selectedOwnerAccId={selectedOwnerAccId}
                        setSelectedOwnerAccId={setSelectedOwnerAccId}
                        setAccountNumber={setAccountNumber}
                        tempStartDate={tempStartDate}
                        tempEndDate={tempEndDate}
                        setTempEndDate={setTempEndDate}
                        setTempStartDate={setTempStartDate}
                        currentStatus={currentStatus}
                        setCurrentStatus={setCurrentStatus}
                      />
                    </div>

                    {/* 
                    
                    This Commented Code is For Navbar Pagination Changes Implementated as per New Design  
                    
                    
                    <div className="bene-txn-viewall-page-select-div">
                    <div
                      style={{
                        height: "34px",
                        width: "70px",
                        padding: "7px 0px 0px 8px",
                        color: "#DADADA",
                        fontSize: "12px",
                      }}
                    >
                      Per page :
                    </div>

                    <div
                      ref={dropdownRef}
                      style={{
                        height: "36px",
                        width: "56px",
                        borderRadius: "0 4px 4px 4px",
                      }}
                      className="statement-type"
                    >
                      <div
                        className="txn-main-btn"
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                        style={{
                          borderRadius: isOpen
                            ? "0 4px 4px 4px"
                            : "0 4px 4px 0px",
                          height: isOpen ? "100px" : "34px",
                        }}
                      >
                        <div
                          style={{
                            height: "36px",
                            width: "57px",
                            backgroundColor: isOpen ? "#667CA3" : "#F9F9F9",
                          }}
                          className="txn_high_to_low_select_select"
                        >
                          <span
                            style={{
                              color: isOpen ? "#f9f9f9" : "#244277",
                              fontSize: "12px",
                              paddingLeft: "8px",
                            }}
                          >
                            {selectedValue}
                          </span>
                          <img
                            src={isOpen ? ArrowUp : ArrowDown8}
                            alt="Status Icon"
                            style={{
                              height: "12px",
                              width: "12px",
                            }}
                            className="status_icon-img"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "56px",
                            top: "36px",
                          }}
                        ></div>
                        <div
                          style={{
                            padding: "5px",
                            top: "30px",
                            width: "56px",
                          }}
                          className="txn_high_to_low_select_scroll"
                        >
                          {dropdownOptions.map((value) => (
                            <div
                              key={value}
                              style={{
                                width: "47px",
                                backgroundColor:
                                  selectedValue == value && "#244277",
                              }}
                              className="txn_select_scroll_com"
                              onClick={() => handleSelect(value)}
                            >
                              {value}
                              <div
                                style={{
                                  width: "56px",
                                  display:
                                    selectedValue == value ? "flex" : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                  paddingRight: "1px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "12px",
                                    width: "11px",
                                  }}
                                  src={allChecker}
                                  alt="check"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bene-txn-viewall-pagination-containers">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Prev"
                    >
                      <div
                        onClick={prevPage}
                        disabled={currentPage === 1}
                        style={{
                          height: "34px",
                          width: "32px",
                          padding: "10px 0px 0px 8px",
                          cursor: currentPage === 1 ? "default" : "pointer",
                        }}
                      >
                        <img
                          src={ArrowLeft}
                          alt=""
                          style={{
                            opacity: currentPage === 1 ? 0.5 : 1,
                            cursor: currentPage === 1 ? "default" : "pointer",
                          }}
                          className="w-[11px] h-[14px]"
                        />
                      </div>
                    </Tooltip>
                    <input
                      type="text"
                      value={inputValue}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      className="bene_txn_viewall_pagination_input"
                      maxLength={String(totalPages).length}
                    />
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title="Next "
                    >
                      <div
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                        style={{
                          height: "34px",
                          width: "74px",
                          padding: "8px 0px 0px 10px",
                          display: "flex",
                          gap: "5px",
                          cursor:
                            currentPage === totalPages ? "default" : "pointer",
                        }}
                      >
                        <span
                          className="text-xs text-center text-[#DADADA]"
                          id="id-firstLetter"
                        >
                          of {totalPages}
                        </span>
                        <img
                          src={ArrowRight}
                          style={{
                            opacity: currentPage >= totalPages ? 0.5 : 1,
                            cursor:
                              currentPage >= totalPages ? "default" : "pointer",
                          }}
                          alt=""
                          className="w-[11px] h-[14px] pt-[1px]"
                        />
                      </div>
                    </Tooltip>
                  </div> */}

                    <div className="bene_txn_viewall_toggle_containers">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Grid"
                      >
                        <Box
                          onClick={() => {
                            // setSearchUserText("");
                            dispatch(setToggle(false));
                            setCurrentPage(1);
                            // setCurrentPageText("01");
                          }}
                          sx={{
                            backgroundColor: toggle ? "" : "#fff",
                          }}
                          className="toggle-box-txn"
                        >
                          <img
                            src={toggle ? GridWhite : GridBlue}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="List"
                      >
                        <Box
                          onClick={() => {
                            setSearchUserText("");
                            dispatch(setToggle(true));
                            // toast.info("Currently in development", toast_position);
                            setCurrentPage(1);
                            // setCurrentPageText("01");
                          }}
                          sx={{
                            backgroundColor: toggle ? "#fff" : "",
                          }}
                          className="toggle-box-txn"
                        >
                          <img
                            src={toggle ? ListBlue : ListWhite}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(currentCategory !== "" ||
            isPaymentMethod ||
            txnAmountFilter ||
            selectedOwnerAccId ||
            selectedBeneAccId ||
            (startDateFilter &&
              endDateFilter &&
              startDateFilter > 0 &&
              endDateFilter > 0 &&
              !isNaN(Number(startDateFilter)) &&
              !isNaN(Number(endDateFilter)))) && (
            <div
              className="bene_txn_filterdropdown-container-div"
              style={{
                height: hideCarousal ? "auto" : "100px",
                width:
                  props?.typepop === "transactionHistorypopup" ? "96%" : null,
                left:
                  props?.typepop === "transactionHistorypopup" ? "2%" : null,
                top: props?.typepop === "transactionHistorypopup" ? "4%" : null,
              }}
            >
              <div className="bene_txn_filterdropdown mt-[100px]">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    gap: "21px",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      className="pr-2 w-[80px]"
                      style={{
                        whiteSpace: "nowrap",
                        flexShrink: 0,
                        marginTop: "18px",
                        color: " #fafafa",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Filtered by :
                    </p>
                    <div
                      className="bene_txn_viewall_filter_div_left"
                      style={{
                        gap: "10px",
                        display: "flex",
                        maxWidth: "1024",
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      ref={scrollRef}
                    >
                      {currentCategory && (
                        <>
                          <div className="bene_txn_viewall_filter_body px-2 mr-[5px]">
                            <div className="bene_txn_viewall_filter_img_div">
                              <img src={rentalImg} alt={currentCategory} />
                            </div>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={currentCategory}
                            >
                              <p
                                className="px-2 text-xs truncate"
                                style={{
                                  flex: "1",
                                  width: "112px",
                                  color: "#FFFFFF",
                                  letterSpacing: " 0px",
                                  textTransform: "capitalize",
                                  opacity: "1",
                                }}
                              >
                                {currentCategory}
                              </p>
                            </Tooltip>
                            <div
                              className="bene_txn_viewall_close_btn"
                              onClick={handleFilterSticker}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              <img
                                src={closeBtnImg}
                                alt="close-btn"
                                className="w-[12px] h-[12px]"
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {startDateFilter > 0 &&
                        endDateFilter > 0 &&
                        !isNaN(Number(startDateFilter)) &&
                        !isNaN(Number(endDateFilter)) && (
                          <div
                            className="bene_txn_viewall_filter_body px-2 mr-[10px]"
                            style={{ width: "231px !important" }}
                          >
                            <div className="px-2 text-xs">
                              {formatDate(new Date(Number(startDateFilter)))}
                            </div>

                            {"-"}
                            <div className="px-2 text-xs">
                              {formatDate(new Date(Number(endDateFilter)))}
                            </div>
                            <div
                              className="bene_txn_viewall_close_btn"
                              onClick={handleFilterDateSticker}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={closeBtnImg}
                                alt="close-btn"
                                className="w-[12px] h-[12px] cursor-pointer "
                              />
                            </div>
                          </div>
                        )}

                      {selectedBeneAccId && (
                        <div className="bene_txn_viewall_filter_body px-2 mr-[5px]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={accountName?.toLowerCase()}
                          >
                            <p
                              className="px-2 text-xs truncate"
                              style={{
                                flex: "1",
                                width: "112px",
                                color: "#FFFFFF",
                                letterSpacing: " 0px",
                                textTransform: "capitalize",
                                opacity: "1",
                              }}
                            >
                              {accountName?.toLowerCase()}
                            </p>
                          </Tooltip>
                          <div
                            className="bene_txn_viewall_close_btn"
                            onClick={() => {
                              if (
                                props?.typepop !== "transactionHistorypopup"
                              ) {
                                handleFilterBeneAccSticker();
                              }
                            }}
                            style={{
                              cursor:
                                props?.typepop === "transactionHistorypopup"
                                  ? "default"
                                  : "pointer",
                              opacity:
                                props?.typepop === "transactionHistorypopup"
                                  ? "0.4"
                                  : "1",
                            }}
                          >
                            {" "}
                            <img
                              src={closeBtnImg}
                              alt="close-btn"
                              className="w-[12px] h-[12px]"
                            />
                          </div>
                        </div>
                      )}
                      {isPaymentMethod && (
                        <div className="bene_txn_viewall_filter_body px-2">
                          <p className="px-2 text-xs">{isPaymentMethod}</p>
                          <div
                            className="bene_txn_viewall_close_btn"
                            onClick={() => {
                              handleFilterPaymentSticker();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img
                              src={closeBtnImg}
                              alt="close-btn"
                              className="w-[12px] h-[12px]"
                            />
                          </div>
                        </div>
                      )}
                      {selectedOwnerAccId && (
                        <div className="bene_txn_viewall_filter_body px-2 mr-[5px]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={formatAccountNumber(accountNumber)}
                          >
                            <p
                              className="px-2 text-xs truncate"
                              style={{
                                flex: "1",
                                width: "122px",
                                color: "#FFFFFF",
                                letterSpacing: " 0px",
                                textTransform: "capitalize",
                                opacity: "1",
                              }}
                            >
                              {formatAccountNumber(accountNumber)}
                            </p>
                          </Tooltip>
                          <div
                            className="bene_txn_viewall_close_btn"
                            onClick={() => {
                              handleFilterPaymentStickerOwner();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img
                              src={closeBtnImg}
                              alt="close-btn"
                              className="w-[12px] h-[12px]"
                            />
                          </div>
                        </div>
                      )}
                      {txnAmountFilter && (
                        <div className="bene_txn_viewall_filter_body px-2">
                          <p className="px-2 text-xs">
                            {"₹ " +
                              Number(txnAmountFilter)?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </p>
                          <div
                            className="bene_txn_viewall_close_btn"
                            onClick={() => {
                              handleFilterAmountMethod();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img
                              src={closeBtnImg}
                              alt="close-btn"
                              className="w-[12px] h-[12px]"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="BeneTxn_CaraouselBar"
                    style={{
                      margin: "0 auto",
                      top: "-10px",
                      visibility: hideCarousal ? "visible" : "hidden",
                    }}
                  >
                    <div className="BeneTxn_CarouselDotbar">
                      <div
                        className="BeneTxn_FirstCaraoDot"
                        style={{
                          width: showSecondContent ? "12px" : "46px",
                          opacity: showSecondContent ? "0.65" : "1",
                          transition: "width 0.3s ease, opacity 0.3s ease",
                        }}
                      ></div>
                      <div
                        className="BeneTxn_SecondCaraoDot"
                        style={{
                          width: showSecondContent ? "46px" : "12px",
                          opacity: showSecondContent ? "1" : "0.65",
                          transition: "width 0.3s ease, opacity 0.3s ease",
                        }}
                      ></div>
                    </div>
                    <div className="BeneTxn_NextAndPrevIcon">
                      <div
                        className="BeneTxn_CarouPrevIcon"
                        onClick={disablePrev ? null : beneTxnPrev}
                      >
                        <img
                          src={PrevIcon}
                          alt=""
                          style={{
                            opacity: showSecondContent ? "1" : "0.5",
                            transition: "opacity 0.3s ease",
                          }}
                        />
                      </div>
                      <div
                        className="BeneTxn_CarouNextIcon"
                        onClick={disableNext ? null : beneTxnNext}
                      >
                        <img
                          src={NextIcon}
                          alt=""
                          style={{
                            opacity: showSecondContent ? "0.5" : "1",
                            transition: "opacity 0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="beneTxnViewAll_middle_section_container"
            style={{ marginTop }}
          ></div>
          <div
            id="txnViewallScrollContainer"
            style={{
              opacity:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "0.8"
                  : "1",
              backgroundColor:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "#000"
                  : "",
              overflowY:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "hidden"
                  : "auto",
              height:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "100vh"
                  : "100%",
              marginTop:
                currentCategory !== "" ||
                startDateFilter ||
                endDateFilter ||
                isPaymentMethod ||
                txnAmountFilter ||
                selectedOwnerAccId ||
                selectedBeneAccId
                  ? "-44pxpx"
                  : "5px",
            }}
          >
            {!toggle ? (
              loading ? (
                <div className="loader-container m-auto justify-center items-center flex">
                  <img src={dataLoading} alt="" />
                </div>
              ) : accumulatedData?.length > 0 ? (
                <div
                  className="flex flex-col items-center p-1 w-full h-full mt-4"
                  style={{
                    opacity:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? "0.3"
                        : "1",
                    background:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? "#000"
                        : "",
                    pointerEvents:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen ||
                      isViewModalOpen ||
                      isViewModalOpenOwner
                        ? "none"
                        : "",
                  }}
                >
                  <BeneTxnViewAllCard
                    data={accumulatedData}
                    lastElementRef={lastElementRef}
                    isSidebarOpen={isSidebarOpen}
                    numberOfPages={totalPages}
                    isCardHovered={isCardHovered}
                    setIsCardHovered={setIsCardHovered}
                    isPopoverOpen={isPopoverOpen}
                    isDistributionPopoverOpen={isDistributionPopoverOpen}
                    typepop={props?.typepop}
                    onClose={props?.onClose}
                  />
                </div>
              ) : setTxnAmount && accumulatedData?.length === 0 ? (
                <div
                  className="flex flex-col items-center justify-center m-auto"
                  style={{
                    opacity:
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? 0.3
                        : 1,
                  }}
                >
                  <img
                    src={noaccount}
                    alt="No accounts found"
                    className="bene-txn-no-acc-image"
                  />
                  <p className="no-beneaccount-text" style={{ width: "314px" }}>
                    No transactions found.
                  </p>
                </div>
              ) : null
            ) : (
              <div
                className={
                  isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                    ? `h-full bg-black opacity-40`
                    : "w-full h-full"
                }
              >
                {loading ? (
                  <div className="loader-container m-auto justify-center items-center flex w-full h-full">
                    <img src={dataLoading} alt="" />
                  </div>
                ) : accumulatedData?.length > 0 ? (
                  <div className="flex flex-col items-center p-1 w-full h-full mt-5">
                    <BeneTxnListView
                      // handleRefresh={handleRefresh}
                      data={accumulatedData}
                      isSidebarOpen={isSidebarOpen}
                      page={currentPage}
                      isPopoverOpen={isPopoverOpen}
                      isDistributionPopoverOpen={isDistributionPopoverOpen}
                      // refreshAllData={refreshAllData}
                      // refreshType={refreshType}
                      // setRefreshtype={setRefreshtype}
                      // setFailedRefreshId={setFailedRefreshId}
                      // failedRefreshId={failedRefreshId}
                      // successRefreshId={successRefreshId}
                      // setSuccessRefreshId={setSuccessRefreshId}
                    />
                  </div>
                ) : searchUserText && accumulatedData?.length === 0 ? (
                  <div
                    className={
                      isSidebarOpen ||
                      isPopoverOpen ||
                      isDistributionPopoverOpen
                        ? `h-full bg-black opacity-40 flex flex-col items-center justify-center m-auto`
                        : "flex flex-col items-center justify-center m-auto"
                    }
                  >
                    <img
                      src={noaccount}
                      alt="No accounts found"
                      className={
                        isSidebarOpen ? "no-acc-image-opacity" : "no-acc-image"
                      }
                    />
                    <p
                      className={
                        isSidebarOpen
                          ? "no-owneraccount-text-opacity"
                          : "no-owneraccount-text"
                      }
                    >
                      No transactions found.
                    </p>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center justify-center m-auto h-full"
                    style={{
                      opacity:
                        isSidebarOpen ||
                        isPopoverOpen ||
                        isDistributionPopoverOpen
                          ? 0.4
                          : 1,
                    }}
                  >
                    <img
                      src={noaccount}
                      alt="No accounts found"
                      className="bene-txn-no-acc-image"
                    />
                    <p
                      className="bene-txn-no-beneaccount-text"
                      style={{ width: "314px" }}
                    >
                      No transactions found.
                    </p>
                  </div>
                )}
                {/* </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BeneTxnViewall;
